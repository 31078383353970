<template>
  <div>
    <div class="row mx-2 my-0">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          shadow-card
          py-5
          px-4
        "
      >
        <h1 class="mb-5">Create New Room</h1>
        <form @submit.prevent="createRoomHelper" validate>
          <input-control
            type="horizontal"
            v-model.trim="room.room_name"
            labelClass="col-md-3 col-lg-2"
            required
          >
            Room Name
          </input-control>
          <div class="form-group row">
            <label
              class="col-md-2 col-form-label col-md-3 col-lg-2"
              for="input-control-injured_at"
            >
              Room end date
            </label>
            <div class="col">
              <Datetime
                id="input-control-injured_at"
                v-model="room.end_date"
                zone="UTC"
                :week-start="7"
                placeholder="DD/MM/YYYY"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></Datetime>
              <span class="text-muted">
                Select a date in the future for when the room should be marked
                for deactivation.
                <br />
                (Default: 3 days from now)
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label
              class="col-md-2 col-form-label col-md-3 col-lg-2"
              for="input-control-injured_at"
            >
              Room Size
            </label>
            <div class="col">
              <div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="room.room_mode"
                    value="normal"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label
                    class="form-check-label font-weight-bold"
                    for="flexRadioDefault1"
                  >
                    2-4 people
                  </label>
                </div>
                <div class="text-muted">
                  Secure peer-to-peer video conferencing for up to 4
                  participants at a time with end-to-end encryption. <br />
                  Ideal for patient consultations, customer meetings, and 1:1s.
                </div>
              </div>
              <div class="mt-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="group"
                    v-model="room.room_mode"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked
                  />
                  <label
                    class="form-check-label font-weight-bold"
                    for="flexRadioDefault2"
                  >
                    2-200 people
                  </label>
                </div>
                <div class="text-muted">
                  Secure video conferencing for up to 200 participants and 24
                  active videos at a time. <br />
                  Ideal for large gatherings, virtual events, but also works
                  great for meetings with only a few participants.
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-md-end">
            <button
              type="submit"
              class="btn btn-theme btn-lg font-weight-bold button-width"
              :disabled="saving"
            >
              <i class="fas fa-spin fa-circle-notch" v-if="saving"></i>
              <span v-else> Create Room </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Datetime } from "vue-datetime";
import dayjs from "dayjs";
export default {
  components: {
    Datetime,
  },
  data() {
    return {
      room: {
        room_name: "",
        room_mode: "group",
        end_date: dayjs().add(3, "days").format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    ...mapState({
      saving: (state) => state.rooms.createRoomLoading,
    }),
  },
  methods: {
    ...mapActions({
      createRoom: "rooms/createRoom",
    }),
    createRoomHelper: function () {
      this.createRoom(this.room).then((res) => {
        if (res) {
          this.$router.push({ name: "rooms" });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.button-width {
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
